.menu {
    display: flex;
    flex-direction: column;
  }
  
  .category {
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .subcategory-menu {
    display: flex;
    flex-direction: column;
  }
  
  .subcategory {
    cursor: pointer;
  }
  
  .back {
    cursor: pointer;
    font-size: 18px;
  }

  .subcategory-menu {
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  
  .subcategory-menu.left {
    animation: desplazar-izquierda 00ms linear normal;
    transform: translateX(0%);
  }
  
  .subcategory-menu.right {
    animation: desplazar-derecha 400ms linear normal;
    transform: translateX(100%);
  }
  
  @keyframes desplazar-izquierda {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes desplazar-derecha {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  