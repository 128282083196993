   /* Mobile styles */
   @media (max-width: 768px) {
     .thumbnails {
       display: none;
     }

     .swiper-pagination-bullet {
       background: black !important;
       width: 25px !important;
       height: 3px !important;
       opacity: 0.5 !important;
     }

     .swiper-pagination-bullet-active {
       opacity: 1;
     }
   }

   .main-image.zoomed img:active {
     cursor: grabbing;
   }

   .thumbnail.active {
     border: none !important;
     filter: brightness(.8) !important;
   }

   .main-image.zoomed {
     overflow: hidden;
   }


   .main-image.zoomed img:active {
     cursor: grabbing;
   }

   .main-image {
     position: relative;
     overflow: hidden;
     width: 690px;
     height: 690px;
   }

   .main-image img {
     transition: transform 0.3s ease;

     @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
     }

     @media (max-width: 768px) {
       width: 100%;
       height: 100%;
     }
   }

   .main-image.zoomed img {
     position: absolute;
     top: 0;
     left: 0;
     cursor: grab;
     transform: scale(1.5);
   }

.zoom-container {
  width: 690px;
  height: 690px;
  overflow: hidden;
  position: relative;
  cursor: zoom-in;
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vw;
  }
}

.zoom-container.zoomed {
  cursor: zoom-out;
}

.zoom-wrapper {
  width: 690px;
  height: 690px;
  overflow: hidden;
  position: relative;
}

.zoomable-image-highres {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
  transition: transform 0.2s;
}

.zoomable-image-highres.zoomed {
  cursor: zoom-out;
}

.zoomable-image-lowres {
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

