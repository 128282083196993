@import "./menu.scss";
@import "./swiper-thumbs-gallery.scss";
@import "./error-box.scss";
/*@import "./scrollbar.scss";*/

/*Tamaños*/
$faldonHeight : 40px;
$navbarHeight : 64px;
/*poner luego 56*/

/*Colores*/
$grisOscuro : #525252;
$grisMedio : #7E7E7E;
$grisClaro1 : #C4C4C4;
$grisClaro2 : #9C9C9C;
$grisClaro3 : #F2F2F2;
$grisClaro4 : #D3D4D5;

$negro : #000000;
$azul : #0094E7;
$rojo : #FF0000;
$verde : #99BF39;
$verdeWhatsapp : #25D366;
$naranja : #F0B223;
$blanco : #FFFFFF;
$fucsia: #e51970;

html,
body {
  font-family: 'Helvetica Now Text';
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Now Text";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*PERSONALIZAR SCROLL FALTA FIREFOX*/
body::-webkit-scrollbar {
  width: 1em;
  display: none;
}

body::-webkit-scrollbar-track {
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: $grisClaro2;
  border-radius: 5px;
}

/*EN SAFARI*/
body {
  margin-bottom: env(safe-area-inset-bottom);
  /* Ajusta el margen inferior seguro */
}

/*EN FIREFOX*/

html {
  scrollbar-width: none;
}

.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.empty-cart {
  height: calc(100vh - 100px) !important;
}

.order-cart {
  height: 342px !important;
}

/*CUSTOM SCROLL AGREGANDO CONTENEDOR SUPERIOR CON CLASE .scroll-container*/

.scroll-container {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 271px);
  scrollbar-width: thin;
  --scrollbar-color-track: transparent;
  --scrollbar-color-thumb: #C4C4C4;

  @media (max-width: 1024px) {
    height: calc(100vh - 271px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
  }

  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 5px;
    /* WebKit */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* WebKit */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border-radius: 10px;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }
}

.scroll-container:focus::-webkit-scrollbar-thumb,
.scroll-container:hover::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
}

.scroll-container:focus,
.scroll-container:hover {
  scrollbar-color: #C4C4C4 transparent;
  /* Firefox */
}

/*para iOS*/
.scroll-container-ios {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 270px - env(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
  scrollbar-width: thin;
  --scrollbar-color-track: transparent;
  --scrollbar-color-thumb: #C4C4C4;

  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 5px;
    /* WebKit */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* WebKit */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border-radius: 10px;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }
}

.scroll-container-ios:focus::-webkit-scrollbar-thumb,
.scroll-container-ios:hover::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
}

.scroll-container-ios:focus,
.scroll-container-ios:hover {
  scrollbar-color: #C4C4C4 transparent;
  /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Ancho del scrollbar para navegadores basados en WebKit */
  height: 8px;
  /* Altura del scrollbar horizontal */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color del pulgar del scrollbar */
  border-radius: 4px;
  /* Bordes redondeados del pulgar del scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color del pulgar del scrollbar al pasar el ratón */
}

.custom-scrollbar {
  scrollbar-width: thin;
  /* Ancho del scrollbar para Firefox */
  scrollbar-color: #888 #f0f0f0;
  /* Colores del pulgar y del track del scrollbar para Firefox */
}

/*desactivar efecto zoom*/

input,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* Desactiva el resaltado de enfoque */
  -webkit-user-select: none;
  /* Desactiva la selección de texto en Safari */
  user-select: none;
  /* Desactiva la selección de texto en otros navegadores */
  touch-action: manipulation;
  /* Mejora la respuesta táctil en dispositivos móviles */
}

.catalog-bar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  background-color: white;
  -webkit-animation: animation-my5jl 300ms forwards;
  animation: animation-my5jl 300ms forwards;
}

.catalog-bar-sticky-mobile {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  -webkit-animation: animation-my5jl 300ms forwards;
  animation: animation-my5jl 300ms forwards;
}

.catalog-bar-sticky-search {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  z-index: 20;
  background-color: white;
  -webkit-animation: animation-my5jl 300ms forwards;
  animation: animation-my5jl 300ms forwards;
}

/*Scroll en filtros*/
#filters-content {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  -webkit-animation: animation-my5jl 300ms forwards;
  animation: animation-my5jl 300ms forwards;
}

#filters {
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin;
  --scrollbar-color-track: transparent;
  --scrollbar-color-thumb: #C4C4C4;
  scrollbar-color: transparent transparent;

  /* Firefox */
}

#filters::-webkit-scrollbar {
  width: 5px;
}

#filters::-webkit-scrollbar-track {
  background-color: transparent;
}

#filters::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

#filters:focus::-webkit-scrollbar-thumb,
#filters:hover::-webkit-scrollbar-thumb {
  background-color: #d9d8d8;
}

#filters:focus,
#filters:hover {
  scrollbar-color: #d9d8d8 transparent;
  /* Firefox */
}

.global-height {
  height: calc(100vh - 50px);
}

.global-height-short {
  height: calc(100vh - 200px);
}

.product-details::-webkit-scrollbar {
  display: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0.1em !important;
}

/* custom checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #111 !important;
  font-family: 'Helvetica Now Text';
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.checkbox:hover {
  color: #737373;
}

/* hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* create custom checkbox */
.check {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* on mouse-over, add border color */
.checkbox:hover input~.check {
  border: 2px solid #000000;
}

/* add background color when the checkbox is checked */
.checkbox input:checked~.check {
  background-color: #000000;
  border: none;
}

/* create the checkmark and hide when not checked */
.check:after {
  content: "";
  position: absolute;
  display: none;
}

/* show the checkmark when checked */
.checkbox input:checked~.check:after {
  display: block;
}

/* checkmark style */
.checkbox .check:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*
.MuiTypography-body1 {
  font-family: 'HelveticaNowText Regular' !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #000000;
 
}

.MuiTypography-body1:hover {
  color: #000000;
}*/

#filters .MuiSvgIcon-root {
  font-size: 1.3rem !important;
  color: #000000 !important;
  /* box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);*/
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #000000 !important;
}

/*
.product {
  img {
    width: 100%;
  }
}*/
/*
.parallax{
  background-image: url(https://scontent-mia3-1.xx.fbcdn.net/v/t1.6435-9/35643646_1759545107466871_7878998443723587584_n.jpg?stp=dst-jpg_p180x540&_nc_cat=100&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=IMWvpbqjLXMAX_voDFh&_nc_ht=scontent-mia3-1.xx&oh=00_AfBKXdmEdzZp2AYdbWQOXtv1ISlFAaEX1Up9GCVQEGtuaw&oe=642F3C34);
}*/

/*
@media (min-width:1280px) {
  .lg\:container {
    max-width: 1200px !important;
  }
}

@media (min-width:1280px) {
  .container {
    max-width: 1200px !important;
  }
}*/

/*
.header {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}*/

.bottom-shadow {
  box-shadow: 0 3px 12px rgba(0, 0, 0, .15);
}

.header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1002;
  top: 0;
  /* transition: box-shadow .3s ease;*/
  /* box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);*/
}

.promo {
  height: $faldonHeight;
}

nav {
  height: $navbarHeight;
}

.navbar__brand {
  transform: scale(1.3);
}

.menu-mobile {
  background: #FFFFFF !important;
  height: 100vmax;
  overflow-y: auto;
}

.banner {

  /* margin-top: $faldonHeight + $navbarHeight;*/
  img {
    width: 100vw;
    height: calc(100vh - 100px);
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: 20% 0%;
    z-index: 0;

    @media (max-width: 1366px) {
      object-position: 85% 15%;
    }

    @media (max-width: 768px) {
      object-position: 50% 15%;
    }

    /*
    @media (max-width: 520px) {
      object-position: 90% 15%;
    }*/
  }
}

.banner-historia {

  /*margin-top: $faldonHeight + $navbarHeight;*/
  img {
    width: 100%;
    z-index: 0;

    @media (max-width: 768px) {
      height: 397px;
    }
  }
}

.banner-img-container {
  transition: opacity 500ms linear 0s;
}

/*
.banner-img {
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
}*/

.slider-button {
  background: #fff;
  padding: 10px 20px;
  font-size: 24px;
}

.cartCount {
  position: absolute;
  right: -4px;
  top: -1px;
  font-size: 11px;
  color: #FFFFFF;
}

.banner-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 1042px;
  height: 223px;
  left: calc(50% - 521px);
  top: calc(50% - 111px);
  background: rgba(0, 0, 0, 0.1);

  &__title {
    line-height: 1.2;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
  }

  &__description {
    margin-top: 10px;
    padding-bottom: 40px;
    color: #FFFFFF;
  }

  &__link {
    display: flex;
    background: #FFFFFF;
    border-radius: 5px;
    width: 214px;
    height: 56px;
  }
}

/*By post*/

.banner .wp-block-cover {

  @media (max-width: 768px) {
    width: fit-content;
    min-height: 397px !important;
  }

  @media (max-width: 412px) {
    width: fit-content;
    min-height: 190px !important;
  }
}

.banner .wp-block-cover__inner-container.cj-left-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
  /* background: rgba(0, 0, 0, 0.1);*/
  padding: 10px;
  position: absolute;
  left: calc(15.5%);
  bottom: calc(18%);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(59% - 200px);
    bottom: calc(20%);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(53% - 170px);
    bottom: calc(20%);
  }
}

.banner .wp-block-cover__inner-container.cj-left-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
  padding: 10px;
  position: absolute;
  left: calc(6.5%);
  bottom: calc(50% - 150px);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
    bottom: calc(50% - 99px);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(50% - 170px);
    bottom: calc(82% - 99px);
  }
}

.banner .wp-block-cover__inner-container.cj-left-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
  padding: 10px;
  position: absolute;
  left: calc(6.5%);
  top: calc(5%);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(50% - 170px);
  }
}

.banner .wp-block-cover__inner-container.cj-center-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 750px;
  padding: 10px;
  position: absolute;
  left: calc(50% - 375px);
  bottom: calc(20%);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
    bottom: calc(20%);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(50% - 170px);
    bottom: calc(20%);
  }
}

.banner .wp-block-cover__inner-container.cj-center-absolute {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 900px;
  padding: 10px;
  position: absolute;
  left: calc(50% - 450px);
  bottom: calc(50% - 150px);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
    bottom: calc(50% - 99px);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(50% - 170px);
    bottom: calc(50% - 99px);
  }
}

.banner .wp-block-cover__inner-container.cj-center-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  width: 600px;
  padding: 10px;
  position: absolute;
  left: calc(50% - 300px);
  top: calc(5%);

  @media (max-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
  }

  @media (max-width: 410px) {
    width: 340px;
    left: calc(50% - 170px);
  }
}

.banner .wp-block-cover__inner-container.cj-right-bottom {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 10px;
  position: absolute;
  right: calc(6%);
  bottom: calc(10%);

  @media (max-width: 1250px) {
    right: 0;
    bottom: calc(20%);
  }

  @media (max-width: 768px) {
    width: 400px;
    right: calc(50% - 200px);
    bottom: calc(20%);
  }

  @media (max-width: 410px) {
    width: 340px;
    right: calc(50% - 170px);
    bottom: calc(20%);
  }
}

.banner .wp-block-cover__inner-container.cj-right-center {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 10px;
  position: absolute;
  right: calc(6%);
  bottom: calc(50% - 150px);

  @media (max-width: 1250px) {
    right: 0;
    bottom: calc(20%);
  }

  @media (max-width: 768px) {
    width: 400px;
    right: calc(50% - 200px);
    bottom: calc(50% - 99px);
  }

  @media (max-width: 410px) {
    width: 340px;
    right: calc(50% - 170px);
    bottom: calc(50% - 99px);
  }
}

.banner .wp-block-cover__inner-container.cj-right-top {
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 10px;
  position: absolute;
  right: calc(6%);
  top: calc(5%);

  @media (max-width: 1250px) {
    right: 0;
    bottom: calc(20%);
  }

  @media (max-width: 768px) {
    width: 400px;
    right: calc(50% - 200px);
  }

  @media (max-width: 410px) {
    width: 340px;
    right: calc(50% - 170px);
  }
}

p.has-extra-large-font-size {
  color: #FFFFFF;
  font-style: normal;
  font-family: "Helvetica Now Text", system-ui, sans-serif;
  font-weight: 600;
  font-size: 60px;
  line-height: 0.8;
  /* margin-bottom: 12px;*/

  @media (max-width: 1366px) {
    font-size: 50px !important;
  }

  @media (max-width: 1024px) {
    font-size: 40px !important;
  }

  @media (max-width: 768px) {
    font-size: 30px !important;
  }
}

p.has-large-font-size {
  color: #FFFFFF;
  font-style: normal;
  font-family: "Helvetica Now Text", system-ui, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 1;
  width: 87%;
  /* margin-bottom: 20px;*/

  @media (max-width: 1366px) {
    font-size: 40px;
  }

  @media (max-width: 1024px) {
    font-size: 35px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

p.has-medium-font-size {
  color: #FFFFFF;
  font-style: normal;
  font-family: "Helvetica Now Text", system-ui, sans-serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
  /*margin-bottom: 34px;*/

  @media (max-width: 1153px) {
    font-size: 350px;
  }

  @media (max-width: 1040px) {
    font-size: 30px;
  }

  @media (max-width: 596px) {
    font-size: 25px;
  }

  @media (max-width: 410px) {
    font-size: 22px;
  }
}

p.has-small-font-size {
  color: #FFFFFF;
  font-style: normal;
  font-family: "Helvetica Now Text", system-ui, sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1;
  margin-bottom: 12px;

  @media (max-width: 1153px) {
    font-size: 22px;
  }

  @media (max-width: 1040px) {
    font-size: 20px;
  }

  @media (max-width: 596px) {
    font-size: 18px;
  }

  @media (max-width: 410px) {
    font-size: 16px;
  }
}

.has-text-align-center {
  text-align: left;
  color: #FFFFFF;
  font-family: "Helvetica Now Text", system-ui, sans-serif;
  font-weight: 700;
}

.wp-block-buttons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 24px;
}

.wp-block-button {
  display: flex;
  justify-content: center;
  margin-right: 24px;

  @media (max-width: 576px) {
    margin-right: 12px;
  }
}

.wp-block-button__link:hover {
  background-color: #323232;
  color: #FFFFFF;
}

.wp-block-button__link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 5px;
  width: 200px;
  height: 48px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 0.875rem;


  @media (max-width: 795px) {
    width: 170px;
    height: 48px;
  }

  /*
  @media (max-width: 576px) {
    font-size: 12px;
    width: 110px;
    height: 30px;
  }
  */
}

.banner-historia .wp-block-cover__inner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 1042px;
  height: auto;
  left: calc(50% - 521px);
  top: calc(50% - 240px);
  background: rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: auto;
    left: 0;
    top: calc(50% - 85px);
    padding: 12px;
  }

  @media (max-width: 412px) {
    left: 0;
    top: calc(50% - 54px);
    padding: 12px;
  }

  .banner-historia p.has-text-align-center.has-large-font-size {
    line-height: 1.2;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;

    @media (max-width: 768px) {
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .banner-historia .has-text-align-center {
    text-align: center;
    padding-bottom: 23px;
    color: #FFFFFF;
    font-size: 24px;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    @media (max-width: 412px) {
      padding-bottom: 8px;
    }
  }

  .banner-historia .wp-block-button {
    display: flex;
    justify-content: center;
  }

  .banner-historia .wp-block-button__link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
    width: 214px;
    height: 56px;
    text-transform: capitalize;

    @media (max-width: 768px) {
      font-size: 14px;
      width: 127px;
      height: 32px;
    }
  }
}

.historia-seccion2 p,
.historia-seccion5 p {
  margin-bottom: 20px;
  line-height: 28.8px;
}

.historia-seccion3 img {
  margin-right: 0 !important;
}

.products .product-description {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categories,
.cart,
.checkout {
  min-height: 400px;
}

.checkout-height {
  height: calc(100vh - 65px);
}

/*
.cart-products-drawer {
  height: calc(100vh - 255px);

  @media (max-width: 1024px) {
    height: calc(100vh - 295px);
  }
}*/

.cart-products,
.checkout-cart {

  th,
  td {
    padding: 10px 0;
  }

  tr {
    border-bottom: 1px solid #ccc;
  }
}

.classic-cart-total-container {
  position: fixed;
  bottom: 0px;
  max-height: 200px;
  height: 100%;
  box-shadow: 0 -6px 18px -8px rgba(0, 0, 0, .25);
  z-index: 9999;
  background-color: #FFFFFF;

  /*  @media (max-width: 768px) {
    width: 390px !important;
  }*/
}

/*
.cart-products-drawer::-webkit-scrollbar {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0.5em !important;
}

.cart-products-drawer::-webkit-scrollbar:vertical {
  width: 10px;
}

.cart-products-drawer::-webkit-scrollbar-button:increment,
.cart-products-drawer::-webkit-scrollbar-button {
  display: none;
}

.cart-products-drawer::-webkit-scrollbar:horizontal {
  height: 10px;
}

.cart-products-drawer::-webkit-scrollbar-thumb {
  background-color: $grisMedio;
  border-radius: 20px;
}

.cart-products-drawer::-webkit-scrollbar-track {
  border-radius: 10px;
}
*/

.classicjeans-checkout-form {
  label {
    display: block;
  }
}

.main-title {
  position: relative;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    bottom: 50%;
    left: 0;
    height: 2px;
    width: 50%;
    background: #222;
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: 0;
    height: 2px;
    width: 50%;
    background: #222;
    z-index: -1;
  }
}

.main-title-inner {
  background: #fff;
  padding: 0 20px;
}

.classic-cart-qty {
  input {
    text-align: center;
  }
}

.footer {
  background: #EDEDED;
}

.copyright {
  background: #EDEDED;
  /*border-top: 1px solid $grisOscuro;*/

}

@media (max-width: 768px) {
  #product-detail .copyright {
    margin-bottom: 80px;
  }
}


/*Menu*/

.sub-menu {
  visibility: hidden;
  display: none;
}

.menulabel:hover>.sub-menu {
  display: block;
  visibility: visible;
}

.MuiDrawer-paper {
  height: fit-content !important;
}

/*slider gallery product*/
/*.image-gallery-fullscreen-button {
    right: 7%;
  }*/

.image-gallery-bullets {
  display: none;
}

.image-gallery-slide .image-gallery-image {
  /* width: auto !important;
    -o-object-fit: contain;
    object-fit: contain;
  object-fit: unset !important;*/
  width: auto !important;
  /*height: 690px !important;*/
  border-radius: 10px;
  /*margin-left: 15px;*/
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  margin: 0 10px 0 0 !important;
}

.image-gallery-thumbnail-image {
  border-radius: 5px;
}

@media (max-width: 1280px) {
  .image-gallery-slide .image-gallery-image {
    object-fit: unset !important;
    width: 533px !important;
    height: 533px !important;
  }
}

@media (max-width: 1160px) {
  .image-gallery-slide .image-gallery-image {
    object-fit: unset !important;
    width: 450px !important;
    height: 450px !important;
  }
}

@media (max-width: 1024px) {
  .image-gallery-slide .image-gallery-image {
    object-fit: unset !important;
    width: 390px !important;
    height: 390px !important;
  }
}

@media (max-width: 950px) {
  .image-gallery-slide .image-gallery-image {
    object-fit: unset !important;
    width: 350px !important;
    height: 350px !important;
  }
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left {
    display: none;
  }

  .image-gallery-slide-wrapper.left {
    width: 100%;
  }

  .image-gallery-fullscreen-button {
    right: 0%;
  }

  .image-gallery-bullets {
    display: block;
  }

  .image-gallery-slide .image-gallery-image {
    /* width: auto !important;
    -o-object-fit: contain;
    object-fit: contain;*/
    object-fit: unset !important;
    width: auto !important;
    height: auto !important;
    border-radius: 10px;
    margin-left: 0px;
  }

  .product-detail .copyright {
    margin-bottom: 80px;
  }
}

.image-gallery-thumbnail {
  border: none !important;
  margin-bottom: 10px;
}

.my-thumbnail-gallery:hover,
.my-thumbnail-gallery:focus,
.my-thumbnail-gallery.active {
  border: none !important;
  filter: brightness(.8) !important;
}

/*SCROLL TOP*/
.App {
  text-align: center;
  height: 5000px;
}

.scrollTop {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 80px;
  left: calc(50% - 20px);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  font-size: 2.5rem !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, .15);
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  align-self: flex-end;
  outline: transparent;

  @media (max-width: 768px) {
    top: 130px;
  }
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

/*
.product {
 @media (max-width: 327px){
    width: 282px !important;
  }
}*/
.menutop {
  padding-top: .70rem;

}

/*Carousel*/
.kVtMtA {
  /*boton izq*/
  top: -12px !important;
  right: 65px !important;
  left: unset !important;
  height: 48px !important;
  width: 48px !important;
  box-shadow: 0 0 0px 0 #0009 !important;
  background-color: $grisClaro4 !important;
}

.bhRnqO {
  /*boton der*/
  top: -12px !important;
  right: 0px !important;
  height: 48px !important;
  width: 48px !important;
  box-shadow: 0 0 0px 0 #0009 !important;
  background-color: $grisClaro4 !important;
}

.bqyzQp {
  position: unset !important;
}

.bghjHX {
  /*ancho*/
  margin: 0 0px !important;
}

/*End Carousel*/

.product-categories-container .MuiAccordionDetails-root {
  display: block;
}

#filters .MuiListItem-gutters,
#mobile-filters .MuiListItem-gutters,
#filters-stores .MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.lupa {
  enable-background: new 0 0 56.966 56.966;
}

.df-classic {
  width: 900px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, 3px) scale(0.75) !important;
}

.PrivateNotchedOutline-legendNotched-4 {
  max-width: 0 !important;
}

.PrivateNotchedOutline-legendNotched-8 {
  max-width: 0 !important;
}

.PrivateNotchedOutline-legendNotched-13 {
  max-width: 0 !important;
}

.PrivateNotchedOutline-legendNotched-16 {
  max-width: 0 !important;
}

.MuiButton-root {
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.MuiButton-containedPrimary {
  background-color: $grisOscuro !important;
  height: 56px !important;
  color: $blanco !important;
}

.MuiOutlinedInput-input {
  padding: 20.5px 14px 16.5px 14px !important;
}

.sidebar-shadow-right {
  box-shadow: 6px -4px 11px 1px rgb(0 0 0 / 5%);
}

.sidebar-shadow-left {
  box-shadow: -9px 0px 11px 1px rgba(0, 0, 0, 0.05);
}

.drawer-width {
  @media (max-width: 768px) {
    width: 504px !important;
  }

  @media (max-width: 504px) {
    width: 100vw !important;
  }
}

.box-fly {
  position: fixed;
  opacity: 0.8;
  /* left: 400px;
  top: 200px; */
  /* scale: 1; */
  width: 100px;
  height: 100px;
  z-index: 70;
  -webkit-transition: all .8s ease-in-out;
  -moz-transition: all .8s ease-in-out;
  -o-transition: all .8s ease-in-out;
  -ms-transition: all .8s ease-in-out;
  transition: all .8s ease-in-out;
}


/*CONFIGURACION DEL MAPA*/
.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 40vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 60vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}

@media screen and (max-width: 768px) {
  .css-1rm05dj-MuiListItem-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


.no_exist_color:after {
  border-bottom: 1px solid #e1e0e0;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(45deg);
  width: 100%;
}

/*CUSTOM DROPDOWN*/
#SortSelect .MuiOutlinedInput-notchedOutline {
  text-align: center !important;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0px !important;
  left: 0;
  margin: 0;
  padding: 0 0px !important;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

#SortSelect .MuiOutlinedInput-input {
  padding: 0px 35px 0px 0px !important;
  font-family: "Helvetica Now Text" !important;
  color: #737373 !important;
}

#SortSelect .MuiSvgIcon-root {
  font-size: 1.3rem !important;
  color: #000000 !important;

}

/*
#menu-demo-simple-select-autowidth {
  margin-left: -60px !important;
}*/

#menu-demo-simple-select-autowidth :nth-child(3) {
  box-shadow: 0px 0px 0px 0px !important;
  font-family: "Helvetica Now Text" !important;
  color: #000000 !important;
}

#menu-demo-simple-select-autowidth :nth-child(3) ul li {
  text-align: right !important;
}

#menu-demo-simple-select-autowidth :nth-child(3) ul li:hover {
  color: #737373 !important;
}

.my-select-form option {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

/* Elimina el fondo azulado para los campos autocompletados en Chrome */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 100px white inset !important;
  /* Fondo blanco forzado */
  -webkit-box-shadow: 0 0 0 100px white inset !important;
  /* Fondo blanco forzado para navegadores basados en WebKit */
  -webkit-text-fill-color: #000 !important;
  /* Mantiene el color del texto negro */
  transition: background-color 0s ease-in-out 0s !important;
  /* Sin transición de fondo */
}

/* Ajustes adicionales para Mozilla Firefox */
input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
  box-shadow: 0 0 0 100px white inset !important;
  /* Fondo blanco forzado */
  -moz-box-shadow: 0 0 0 100px white inset !important;
  /* Fondo blanco para Firefox */
  -moz-text-fill-color: #000 !important;
  /* Mantiene el color del texto negro */
}

/* Ajustes adicionales para asegurar consistencia en otros navegadores */
input:-internal-autofill-selected,
textarea:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: white !important;
  /* Fondo blanco */
  color: inherit !important;
  /* Mantener el color del texto original */
}

.MuiFilledInput-root {
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color: #FFFFFF !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

/* Estilo personalizado para el FormControl con variante 'filled' */
.checkout .MuiFormControl-root .MuiFilledInput-input {
  background-color: white !important;
  /* Fondo siempre blanco */
  transition: background-color 0.3s ease;
  /* Transición suave */
}

.checkout .MuiFormControl-root .MuiFilledInput-input:hover {
  background-color: white !important;
  /* Fondo blanco en hover */
}

.checkout .MuiFormControl-root .MuiFilledInput-input.Mui-focused {
  background-color: white !important;
  /* Fondo blanco en foco */
}

.MuiFilledInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #F1F3F5 !important;
  pointer-events: none;
}

.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #F1F3F5 !important;
  pointer-events: none;
}

.last-input .MuiFilledInput-underline:before {
  border-bottom: 0px solid #F1F3F5 !important;
}

.last-input .MuiFilledInput-underline:after {
  border-bottom: 0px solid #F1F3F5 !important;
}

.MuiFilledInput-input {
  padding: 30px 2px 14px !important;
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #F1F3F5 !important;
  /* Restaurar el color del borde del focus */
  border-width: 1px;
  /* Restaurar el ancho del borde */
}

.MuiFormControl-root .MuiFilledInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #F1F3F5 !important;
  /* Color del borde del outline */
  border-width: 1px;
  /* Ancho del borde */
}

.MuiFormControl-root .MuiFilledInput-underline:after {
  border-color: #F1F3F5 !important;
  /* Color del borde del outline */
  border-width: 1px;
  /* Ancho del borde */
}

.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #F1F3F5 !important;
  /* Color del borde del outline cuando el Select está en foco */
  border-width: 1px;
  /* Ancho del borde del outline */
}

.cuotas {
  color: rgba(0, 0, 0, 0.44) !important;
  padding: 0;
  font-size: 0.875rem !important;
  font-family: "Helvetica Now Text" !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.cuotas .MuiFilledInput-input {
  padding: 14px 2px 14px !important;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.44) !important;
  padding: 0;
  font-size: 0.875rem !important;
  font-family: "Helvetica Now Text" !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.MuiFormLabel-root.Mui-focused {
  color: #737373 !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.MuiFormLabel-root.Mui-error {
  color: #f44336;
}

.MuiFormLabel-colorSecondary.Mui-focused {
  color: #f50057;
}

.MuiFormLabel-asterisk.Mui-error {
  color: #f44336;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(2px, 15px) scale(0.7) !important;
}

.MuiInputLabel-filled {
  z-index: 1;
  transform: translate(2px, 23px) scale(1) !important;
  pointer-events: none;
}

.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(2px, 20px) scale(1) !important;
  pointer-events: none;
}

.form-login .MuiInputLabel-outlined,
.register-new-user .MuiInputLabel-outlined,
.check-password .MuiInputLabel-outlined,
.code-confirmation .MuiInputLabel-outlined,
.code-password .MuiInputLabel-outlined,
.reset-password .MuiInputLabel-outlined,
.ticket .MuiInputLabel-outlined,
.devoluciones .MuiInputLabel-outlined,
.search-ticket .MuiInputLabel-outlined,
.editar-perfil .MuiInputLabel-outlined {
  padding-left: 15px;
}

.form-login .MuiInputLabel-outlined.MuiInputLabel-shrink,
.register-new-user .MuiInputLabel-outlined.MuiInputLabel-shrink,
.check-password .MuiInputLabel-outlined.MuiInputLabel-shrink,
.code-confirmation .MuiInputLabel-outlined.MuiInputLabel-shrink,
.code-password .MuiInputLabel-outlined.MuiInputLabel-shrink,
.reset-password .MuiInputLabel-outlined.MuiInputLabel-shrink,
.ticket .MuiInputLabel-outlined.MuiInputLabel-shrink,
.devoluciones .MuiInputLabel-outlined.MuiInputLabel-shrink,
.search-ticket .MuiInputLabel-outlined.MuiInputLabel-shrink,
.editar-perfil .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, 3px) scale(0.75) !important;
}

.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 0.875rem !important;
  box-sizing: border-box;
  align-items: center;
  font-family: "Helvetica Now Text" !important;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.MuiSelect-select:focus {
  background-color: #FFFFFF !important;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.58) !important;
}

.close-icon {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  background-image: url("img/remove-cross-icon.svg");
}

/*
.close-icon {
  width: 24px;
  height: 24px;
  display:block;
  border-radius: 50%;
  background-color: black;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='8' stroke-linecap='round' stroke-miterlimit='10' d='M8 8 L24 24 M8 24 L24 8'/%3E%3C/svg%3E");
}*/


.shop-icon {
  width: 48px;
  height: 48px;
  display: block;
  background-color: rgb(255, 255, 255);
  background: url("img/shop-icon.png") no-repeat;
}

.home-icon {
  width: 48px;
  height: 48px;
  display: block;
  background-color: rgb(255, 255, 255);
  background: url("img/actions-go-home-icon.png") no-repeat;
}

#cuots-html.MuiInputBase-input {
  color: #737373 !important;
}


/*Loader CSS*/
.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  margin: 8em auto;
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

.loader:before {
  left: -3.5em;
}

.loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #000000;
  }

  40% {
    box-shadow: 0 2.5em 0 0 $grisOscuro;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #000000;
  }

  40% {
    box-shadow: 0 2.5em 0 0 $grisOscuro;
  }
}


/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5, .5, .5, 360deg);
  }

  to {
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-135deg);
  }

  75% {
    transform: rotate(-225deg);
  }

  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: .25;
    transform: scale(.75);
  }
}

/* GRID STYLING */

.spinner-box {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.three-quarter-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear 0s infinite;
}

/*Efecto desplazamiento para moviles de la promo**/

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .texto-desplazado {
    animation: desplazar-izquierda 8s linear infinite;
    white-space: nowrap;
    transform: translateX(100%);
  }

  @keyframes desplazar-izquierda {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}

.promo-container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}


.whatsapp-circle {
  align-items: center;
  align-self: flex-end;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 12px rgba(0, 0, 0, .15);
  color: #4fce5d;
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  outline: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 64px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 3s linear infinite;
}

/*Diseño para los botones del thumbs*/
.thumbnails-container {
  position: relative;
}

.thumbnails {
  max-height: 690px;
  /* Limitar el alto máximo al de la imagen principal */
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.thumbnails::-webkit-scrollbar {
  display: none;
}

.nav-button {
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  /* Fondo semitransparente para los botones */
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  /* Para superponer los botones sobre las imágenes */
  left: 5%;
  transform: translateX(-10%);
  /* Centrar horizontalmente */
  z-index: 10;
  /* Asegurar que los botones estén encima de las imágenes */
}

.nav-button.up {
  top: -35px;
  /* Posicionar el botón de "arriba" al inicio */
}

.nav-button.down {
  bottom: -35px;
  /* Posicionar el botón de "abajo" al final */
}

.nav-button:hover {
  background-color: rgba(240, 240, 240, 0.9);
}

/*Fin diseño thumbs*/

.custom-line-through {
  position: relative;
  overflow: hidden;
  /* Para contener el pseudoelemento dentro del div */
}

.custom-line-through::after {
  content: '';
  position: absolute;
  left: 11px;
  top: 16px;
  width: 100%;
  height: 1px;
  /* O el grosor que prefieras para la línea */
  background-color: currentColor;
  /* Para que herede el color del texto */
  transform: rotate(-45deg);
  /* Ajusta el ángulo según necesites */
  transform-origin: left center;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.custom-focus:focus {
  outline: none;
  box-shadow: none;
}

.my-fits-class {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
}

.my-fits-class:hover {
  opacity: 1;
  box-shadow: 0 0 6px 1px #d0cccc;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(201, 198, 198, 1);
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.classic-text-family {
  font-family: "Helvetica Now Text" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-fits-jeans .slick-next,
.slider-fits-jeans .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 37.5%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;

  @media (max-width: 1535.9px) {
    top: 33%;
  }
}

.slider-fits-jeans .slick-prev {
  left: -25px;
}

.slider-fits-jeans .slick-next {
  right: -17px;
}

.slick-button-prev img,
.slick-button-next img {
  display: none;
}

.slick-button-prev,
.slick-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, .15);
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  cursor: pointer;
  position: absolute !important;
  z-index: 20 !important;
}

.slick-button-prev:after,
.slick-rtl .slick-button-next:after {
  content: "‹";
}

.slick-button-next:after,
.slick-rtl .slick-button-prev:after {
  content: "›";
}

.slick-button-prev:hover,
.slick-button-next:hover {
  border-width: 2px;
}

.slick-button-prev::after {
  font-size: 40px;
  font-weight: 200;
  color: black;
  position: relative;
  margin-left: 12px;
  margin-top: -9px;
}

.slick-button-next::after {
  font-size: 40px;
  font-weight: 200;
  color: black;
  position: relative;
  margin-left: 15px;
  margin-top: -9px;
}

@media (max-width: 1023px) {

  .slick-button-prev,
  .slick-button-next {
    display: none;
  }
}

.builder-button-prev,
.builder-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  cursor: pointer;
  position: absolute !important;
  z-index: 20 !important;
}

.builder-button-prev::after {
  position: relative;
  margin-left: 12px;
  margin-top: -9px;
}

.builder-button-next::after {
  position: relative;
  margin-left: 15px;
  margin-top: -9px;
}