.error-box {
    background-color: #f9e7ed;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    display: flex;
  }
  
  .error-box p {
    color: #AE365A;
    margin: 0 0 2px 0;
    font-weight: bold;
  }
  
  .error-box ul {
    list-style-type: none;
    padding: 0;
  }
  
  .error-box li {
    color: #AE365A;
    font-size: 0.9em;
  }
  
  .error-box .error-ico {
    color: #AE365A;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #AE365A;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
  }